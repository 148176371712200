import React, {useContext, useState} from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { ThemeContext } from '../context/ThemeContext';
import "../style/NavigationBar.css";

export default function NavigationBar() {
	const { darkTheme, toggleTheme } = useContext(ThemeContext);
	const [isDark, setIsDark] = useState(true);

	const changeTheme = ()=>{
		toggleTheme();
		setIsDark(!isDark);
	}

	return (
		<div>
			<Navbar bg="#000008" variant="#000008" 
				className={`rounded-pill mt-2 m-auto p-3 fixed-top navigation-bar
					${isDark ?'navbar-dark':  'navbar-light'}`
				}>
				<Container className="justify-content-center">
					<Nav >
						<Nav.Link href="#intro" style={{ color: darkTheme ? 'white' : '#0c0c35' }} className="glow-on-hover theme">About</Nav.Link>
						<Nav.Link href="#experience" style={{ color: darkTheme ? 'white' : '#0c0c35' }} className="glow-on-hover">Experience</Nav.Link>
						<Nav.Link href="#projects" style={{ color: darkTheme ? 'white' : '#0c0c35'  }} className="glow-on-hover">Projects</Nav.Link>
						<button onClick={changeTheme} style={{ background: 'none', border: 'none', marginLeft: '10px' }}>
							<i className={`bi ${isDark ? 'bi-sun icon-light-color': 'bi-moon-fill icon-dark-color' }`}></i>
						</button>
					</Nav>
				</Container>
			</Navbar>
		</div>
	);
}
