import { useContext } from "react";
import Constellation from "./components/Constellations";
import NavigationBar from "./components/NavigationBar";
import './App.css';
import { ThemeContext } from "./context/ThemeContext";
import Introduction from "./components/Introduction";
import Experience from "./components/Experience";
import Projects from "./components/Projects";
import Footer from "./components/Footer"

export default function App() {
  const { darkTheme } = useContext(ThemeContext);
  
  return (
    <div className={`App ${darkTheme ? "dark-theme" : "light-theme"}`}>
          <Constellation theme = {darkTheme ? "dark": "light"}/>
          <NavigationBar/>
          <Introduction/>
          <Experience/>
          <Projects/>
          <Footer/>
    </div>
  );
}
