import React, { useEffect, useMemo, useState, useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

export default function Constellation(props) {
  const [particlesContainer, setParticlesContainer] = useState();

  const particlesInit = useCallback(async engine => {
    console.log(engine);
    await loadFull(engine);
  }, []);

  const particlesLoaded = (container) => {
    setParticlesContainer(container);
  };

  useEffect(() => {
    if (particlesContainer && particlesContainer.currentTheme !== props.theme) {
      particlesContainer.loadTheme(props.theme);
    }
  }, [props.theme, particlesContainer]);

  return useMemo(
    () => (
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          fullScreen: {
                enable: true,
                zIndex: -1
          },
          background: {
            color: {
              value: "#000008"
            }
          },
          fpsLimit: 120,
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "push"
              },
              onHover: {
                enable: true,
                mode: "repulse"
              },
              resize: true
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 2,
                opacity: 0.8,
                size: 40
              },
              push: {
                quantity: 1
              },
              repulse: {
                distance: 200,
                duration: 0.4
              }
            }
          },
          particles: {
            color: {
              value: "#000777"
            },
            links: {
              color: "#777777",
              distance: 100,
              enable: true,
              opacity: 0.5,
              width: 1
            },
            collisions: {
              enable: true
            },
            move: {
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: false,
              speed: 0.5,
              straight: false
            },
            number: {
              density: {
                enable: true,
                area: 700
              },
              value: 100
            },
            opacity: {
              value: 0.5
            },
            shape: {
              type: "circle"
            },
            size: {
              value: { min: 1, max: 3 },
          },
          },
          themes: [
            {
              name: "dark",
              default: {
                value: true,
                mode: "dark"
              },
              options: {
                background: {
                  color: "#000008"
                },
                particles: {
                  color: {
                    value: "#10f0"
                  }
                }
              }
            },
            {
              name: "light",
              default: {
                value: true,
                mode: "light"
              },
              options: {
                background: {
                  color: "#f5f8ff"
                },
                particles: {
                  color: {
                    value: "#10f0"
                  }
                }
              }
            }
          ],
          detectRetina: true
        }}
      />
    ),[]
  );
}

