const summary = {
  username: "Maleeha Khalid",
  title: "Hi there, I'm Maleeha",
  description: "I am a Software Engineer with over 3.5 years of experience in Backend Development, Machine Learning, and Full Stack Development. Throughout my career, I have worked with various programming languages, libraries, frameworks, and technologies such as Java, Spring Boot, React, Docker, Python, GitHub Actions, and Azure. My career began as an AI teaching assistant at UET Lahore, where I taught AI labs and assisted over 50 students with their coursework. At Confiz, I have led scrum activities across multiple projects in the FinTech and Retail domains, and have been recognized twice for my ownership and commitment to my work."
}

const socialMediaLinks = {
  github: "https://github.com/Maleehak",
  linkedin: "https://www.linkedin.com/in/maleehak",
  gmail: "mailto:maleehakdev@gmail.com",
  resumeLink: "https://drive.google.com/file/d/16Ola-E1Y7xRdbQi1eHe-wddHOAvuRkFz/view?usp=sharing",
  twitter: "https://x.com/maleehak_dev"
};


const workExperiences = { 
  experience: [
    {
      role: "Software Engineer - Fintech",  
      company: "Confiz Limited",
      date: "Feb 2023 – Present",
      desc: "Working on the launch of the FinTech application with major focus on providing loans to the customers",
      descBullets: [
        "Implemented seamless checkout system , handled the processing of pending transactions by implementing jobs and localized of the entire application to support both Arabic and English languages",
        "Deployed and managed applications on Docker, ensuring seamless integration and scalability and used event- based programming to trigger real-time email and mobile notifications, improving user engagement and responsiveness",
        "Managed Monolithic - Micro-services based hybrid project, owning features and utilizing UML diagramming tools for effective communication across multiple teams",
        "Tools: Java, Springboot, Docker, Mockito, Kubernetes, Github Actions"
      ]
    },
    {
      role: "Software Engineer - Retail",   
      company: "Confiz Limited",
      date: "May 2021 – Jan 2023",
      desc: "Collaborated with remote teams for release of Online Ordering of Custom Cakes.",
      descBullets: [
        "Developed and managed Java Spring Boot based RESTful systems in the microservices architecture and actively resolved security vulnerabilities for product quality",
        "Implemented the horizontal and vertical scaling of applications, ensuring optimal performance and reliability",
        "Conducted load testing using JMeter during special holidays and supported over 20+ microserivces, employing JUnit and Mockito for unit testing to ensure 80%+ test coverage and utilized Splunk for monitoring",
        "Managed deployment on the cloud, handling configurations and secrets with Vault to maintain security and efficiency",
        "Tools: Java, Springboot, React, WCNP(Walmart Cloud Native Platform), Azure, JMeter, and Mockito"
      ]
    },
    {
      role: "Artificial Intelligence Teaching Assistant",  
      company: "University of Engineering and Technology",
      date: "Jan 2015 – Sep 2015",
      desc: "Worked as a Teaching assistant at my university during the senior",
      descBullets: [
        "Prepared and evaluated course content, assisted and provided technical guidance to class of 50+ students on MIT 6.034 Artificial Intelligence Labs",
        "While working on the Python and mainly on Keras, developed Keras 2.4.0 VSCode extension which is being used by 17K+ users",
        "Tools: Python, Keras, OpenCV, Numpy"
      ]
    },
  ]
};

const projects = {
  title: "Projects",
  projects: [
    {
      id: 1,
      title: "Brief Me",
      desc: "Software designed to summarise the content of Youtube video within few seconds",
      github: "https://github.com/Maleehak/BriefMe",
      img: "/images/brief-me.png",
      website: "https://maleehak.github.io/BriefMe/",
      techStack: ["Java", "Springboot", "Github Actions", "GCP", "React"],
      tag: ["All", "Web"]
    },
    {
      id: 2,
      title: "Keras 2.4.0 VS Code Extension",
      desc: "Simple code snippets for Keras 2.4.0 to boast developers' productivity",
      github: "https://github.com/Maleehak/VS-code-Keras-2.4.0-Extension",
      img: "/images/keras.png",
      website: "https://marketplace.visualstudio.com/items?itemName=MaleehaKhalid.pks",
      techStack: ["Keras"],
      tag: ["All", "ML"]
    },
    {
      id: 3,
      title: "VisionX",
      desc: "An AI based conversational application that interacts with the user(visually impaired person) to entertain his/her query using Speech recognition, NLP and Image processing.",
      github: "https://github.com/Mahnoor17/Conversational-smart-guide-for-visually-impaired-people",
      img: "/images/vision.png",
      techStack: ["Python", "Tkinter", "Keras"],
      tag: ["All", "ML"]
    },{
      id: 4,
      title: "Car Number Plate Detection",
      desc: "Car Recognition and Number Plate hiding from Image, Video or Web Cam using Haar cascade and OpenCV",
      github: "https://github.com/Maleehak/Car-number-plate-recognition-using-OpenCV",
      img: "/images/car.png",
      techStack: ["Python", "OpenCV", "Numpy"],
      tag: ["All", "ML"]
    }
  ]
};


const achievementSection = {

  title: "Accomplishments",
  achivements: [
    {
      title: "Value Champion (Ownership)",
      subtitle: "Recognition award for my ownership and commitment to my work- Confiz Townhall '23 and '24"
    },
    {
      title: "Confiz CodeJam 20223",
      subtitle: "Runner-up of Confiz company-wide hackerthon 2023"
    },
    {
      title: "Ignite National Technology Fund for innovative Final Year Project",
      subtitle: "Our FYP was shortlisted in National Grassroots ICT Research Initiative 2020-21 for financial funding by the government of Pakistan."
    },
    {
      title: "Stackoverflow contributor",
      subtitle: "I volunteered as a Stackoverflow contributor and was top 2% in year 2021"
    },
    {
      title: "Microsoft Learn Student Ambassador",
      subtitle: "First female student from UET Lahore to be selected as Microsoft Learn Student Ambassador"
    },
    {
      title: "Merit Scholarship",
      subtitle: "Merit Scholarship based on excellent performance in consecutive semesters"
    }
  ]
};


const contactInfo = {
  number: "+92-3244266831",
  email_address: "maleehakdev@gmail.com"
};


export { summary, socialMediaLinks, workExperiences, projects, achievementSection, contactInfo};
