import React, {useContext} from 'react';
import {
  MDBFooter,
  MDBContainer
} from 'mdb-react-ui-kit';

import { Button } from "react-bootstrap";
import { socialMediaLinks } from '../portfolio';
import { ThemeContext } from '../context/ThemeContext'; 

export default function App() {
    const { darkTheme } = useContext(ThemeContext);
    return (
        <MDBFooter className='text-center'>
        <div className='text-center' style={{ backgroundColor: darkTheme ? '#0c0c35': 'white' }}>
            <section>
                <Button 
                    href={socialMediaLinks.twitter}
                    target="_blank" 
                    rel="noopener noreferrer"
                    variant="link"
                    className="p-3"
                    >
                    <i class="bi bi-twitter"></i>
                </Button>

                <Button 
                    href={socialMediaLinks.gmail}
                    target="_blank" 
                    rel="noopener noreferrer"
                    variant="link"
                    className="p-3"
                    >
                    <i class="bi bi-google"></i>
                </Button>

                <Button 
                    href={socialMediaLinks.linkedin}
                    target="_blank" 
                    rel="noopener noreferrer"
                    variant="link"
                    className="p-3"
                    >
                    <i class="bi bi-linkedin"></i>
                </Button>


                <Button 
                    href={socialMediaLinks.github}
                    target="_blank" 
                    rel="noopener noreferrer"
                    variant="link"
                    className="p-3"
                    >
                    <i class="bi bi-github"></i>
                </Button>

            </section>
        </div>
        
        </MDBFooter>
    );
}