import React from "react";
import { Button } from "react-bootstrap";
import "../style/ProjectCard.css"

const ProjectCard = ({ title, desc, github, img, website, techStack}) => {
  return (
    <div>
      <div className="h-52 md:h-52 rounded-t-xl relative group"
        style={{ backgroundImage: `url(${img})` , backgroundSize: "cover"}}
      >
        <div className="overlay items-center justify-center absolute top-0 left-0 w-full h-full bg-[#181818] hidden group-hover:flex group-hover:bg-opacity-80 transition-all duration-500 ">
          {github && <Button 
                href={github} 
                target="_blank" 
                rel="noopener noreferrer"
                variant="link"
                className="p-3"
                >
                <i class="bi bi-github" style={{fontSize: "3rem", marginLeft: "20px"}}></i>
            </Button>}

            { website && <Button 
                href={website} 
                target="_blank" 
                rel="noopener noreferrer"
                variant="link"
                className="p-3"
                >
                <i class="bi bi-globe" style={{fontSize: "3rem"}}></i>
            </Button>}
        </div> 
      </div>
      <div className="mt-3 py-6 px-4">
        <h5 className="project-name" style={{color: "rgb(60, 110, 245, 0.866)"}} >{title}</h5>
        <p className="desc">{desc}</p>
      </div>
      <div className="px-4">
      {techStack.map(stack => (
          <span class="badge badge-pill">{stack}</span>
        ))}
      </div>
    </div>
  );
};

export default ProjectCard;
