import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { workExperiences } from "../portfolio";
import "../style/Experience.css";

export default function Experience() {

  return (
    <MDBContainer className="py-1">
      <MDBRow className="px-5">
        <MDBCol>
          <h2 style={{marginBottom:"50px"}} className="experience" id="experience">Experience</h2>
          <ul className="timeline-3">
            {workExperiences.experience.map(experience => 
                <li>
                  <p style={{color: "rgb(60, 110, 245, 0.866)"}} className="company-name">
                    {experience.company}
                  </p>
                  <p className="float-end date">
                    {experience.date}
                  </p>
                  <p className="role">{experience.role}</p>
                  <ul>
                    {experience.descBullets.map( desc => 
                      <li className="desc">{desc}</li>
                    )
                  }
                </ul>
                <div style={{marginBottom: "50px"}}></div>
              </li>
             )}
          </ul>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}