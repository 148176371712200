import React, {useContext} from "react";
import "../style/Introduction.css";
import { summary, socialMediaLinks } from "../portfolio";
import { Button } from "react-bootstrap";
import { ThemeContext } from '../context/ThemeContext';


export default function Introduction() {
    const { darkTheme } = useContext(ThemeContext);

    const handleClick = () => {
        window.open(socialMediaLinks.resumeLink, '_blank', 'noopener,noreferrer');
      };
    
    const handleContactButtonClick = () => {
        window.open(socialMediaLinks.linkedin, '_blank', 'noopener,noreferrer');
      };
      
    return (
        <div>
        <div className="intro-main" id="intro">
        <div className="intro-main">
            <div className="intro-text-div">
                <h1 className="intro-text">
                {" "}
                {summary.title}
                {" "}
                </h1>
                <p className="intro-text-p subTitle">{summary.description}</p>
            </div>
        </div>
        </div>
        <div className="btn-container">
            <Button style={{ 
                backgroundColor: darkTheme ? 'white' : '#0c0c35',
                color: darkTheme ? '#0c0c35' : 'white',
                borderRadius: "20px"
            }} onClick={handleClick} className="glow-on-hover button-cv">Download CV</Button>

            <Button style={{ 
                backgroundColor: darkTheme ? 'white' : '#0c0c35',
                color: darkTheme ? '#0c0c35' : 'white',
                borderRadius: "20px",
                marginLeft: "30px"
            }} onClick={handleContactButtonClick} className="glow-on-hover button-get-in-touch">Get In Touch</Button>
        </div>
        </div>
    );
}
